import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import FormService from "../../services/FormService";
import { MultiSelect } from "primereact/multiselect";

// Define types for the API response
interface FormType {
  formid: number;
  formname: string;
}

interface ApiResponse {
  status: number;
  data: FormType[];
}

// Dynamic Field interface for new JSON structure
interface DynamicField {
  formfield: string;
  fieldtype: string;
  fieldlabel: string;
  dropdown?: string[];
  fieldcheckbox?: string[];
  value?: string[];
}

interface DynamicFieldsApiResponse {
  [key: string]: DynamicField;
}

interface AddFormPopupProps {
  visible: boolean;
  onHide: () => void;
  onSubmit: (formData: any) => void;
  formData: any;
  title: string;
  customerid: any;
  toast: React.RefObject<any>;
}

const AddFormPopup: React.FC<AddFormPopupProps> = ({
  visible,
  onHide,
  formData,
  title,
  onSubmit,
  customerid,
  toast,
}) => {
  const [formType, setFormType] = useState<number | null>(null);
  const [formTypeOptions, setFormTypeOptions] = useState<{ label: string; value: number }[]>([]);
  const [dynamicFields, setDynamicFields] = useState<DynamicFieldsApiResponse>({});
  const [formValues, setFormValues] = useState<{ [key: string]: string | string[] }>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      resetForm();
      fetchFormTypes();
    }
  }, [visible]);

  const handleSave = async () => {
    if (!formType) {
      toast.current?.show({ severity: "warn", summary: "Warning", detail: "Please select a form type", life: 3000 });
      return;
    }

    const savedData = {
      formType,
      ...formValues,
    };

    try {
      await submitFormAttribute(formType as number, formValues);
      onSubmit(savedData);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  const handleReset = () => {
    resetForm();
    onHide();
  };

  const resetForm = () => {
    setFormType(null);
    setDynamicFields({});
    setFormValues({});
    setIsSubmitted(false);
  };

  const fetchFormTypes = async () => {
    try {
      const res = await FormService.GetFormsForExpert();
      const typedResponse = res as ApiResponse;
      if (typedResponse.status === 200) {
        setFormTypeOptions(
          typedResponse.data.map((form) => ({
            label: form.formname,
            value: form.formid,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to fetch form types", error);
    }
  };

  const fetchDynamicFields = async (formTypeId: number) => {
    const data = { formid: formTypeId, customerid: customerid };
  
    try {
      const response = await FormService.GetFormsAttributes(data);
      const typedResponse = response as { status: number; data: DynamicFieldsApiResponse };
  
      if (typedResponse.status === 200) {
        setDynamicFields(typedResponse.data);
  
        // Populate formValues with initial values based on the submitted data
        const initialFormValues = Object.keys(typedResponse.data).reduce((acc, key) => {
          acc[key] = typedResponse.data[key].value ?? (typedResponse.data[key].fieldtype === "dropdown" || typedResponse.data[key].fieldtype === "checkbox" ? [] : "");
          return acc;
        }, {} as { [key: string]: string | string[] });
  
        setFormValues(initialFormValues);
      }
    } catch (error) {
      console.error("Failed to fetch dynamic fields", error);
    }
  };
  

  const handleFormTypeChange = (value: number) => {
    setFormType(value);
    fetchDynamicFields(value);
  };

  const handleInputChange = (key: string, value: string | string[]) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const renderDynamicFields = () => {
    return Object.keys(dynamicFields).map((key) => {
      const { formfield, fieldtype, fieldlabel, dropdown, fieldcheckbox } = dynamicFields[key];
      const value = formValues[key];
  
      return (
        <div
          key={key}
          className="view-popup-field"
          style={{ display: "flex", alignItems: "center", gap: "1rem", marginBottom: "1rem" }}
        >
          <label htmlFor={key} style={{ width: "150px" }}>{formfield}</label>
  
          {fieldtype === "dropdown" && dropdown && dropdown.length > 0 ? (
            <Dropdown
              id={`${key}-dropdown`}
              value={value || ""}
              options={dropdown.map((option: string) => ({ label: option, value: option }))}
              onChange={(e) => handleInputChange(key, e.value)}
              placeholder={fieldlabel}  // Show placeholder if value is empty
              style={{ width: "100%", flex: 1 }}
            />
          ) : fieldtype === "checkbox" && fieldcheckbox && fieldcheckbox.length > 0 ? (
            <MultiSelect
              id={`${key}-checkbox`}
              value={Array.isArray(value) ? value : []}
              options={fieldcheckbox.map((option: string) => ({ label: option, value: option }))}
              onChange={(e: { value: string[] }) => handleInputChange(key, e.value)}
              placeholder={fieldlabel}  // Show placeholder if no selection
              style={{ width: "100%", flex: 1 }}
            />
          ) : (
            <InputText
              id={key}
              value={typeof value === "string" ? value : ""}
              onChange={(e) => handleInputChange(key, e.target.value)}
              placeholder={value ? "" : fieldlabel}  // Show placeholder if value is empty
              style={{ width: "100%", flex: 1 }}
            />
          )}
        </div>
      );
    });
  };
  
  

  const submitFormAttribute = async (formType: number, formValues: { [key: string]: string | string[] }) => {
    const payload = {
      customerid: customerid,
      formId: formType,
      formattributes: JSON.stringify(
        Object.keys(formValues).reduce((acc, key) => {
          acc[key] = {
            value: formValues[key],
            formfield: dynamicFields[key]?.formfield || key,
            fieldlabel: dynamicFields[key]?.fieldlabel,
            fieldtype: dynamicFields[key]?.fieldtype || "",
            dropdown: dynamicFields[key]?.dropdown || [],
            fieldcheckbox: dynamicFields[key]?.fieldcheckbox || []
          };
          return acc;
        }, {} as {
          [key: string]: {
            value: string | string[];
            formfield: string;
            fieldlabel: string;
            fieldtype: string;
            dropdown?: string[];
            fieldcheckbox?: string[];
          };
        })
      ),
    };

    try {
      const response = await FormService.SubmitFormAttribute(payload);
      const typedResponse = response as ApiResponse;

      if (typedResponse.status === 200) {
        toast.current?.show({ severity: "success", summary: "Success", detail: "Form submitted successfully", life: 3000 });
        console.log("Form submitted successfully");
      } else if (typedResponse.status === 400) {
        const errorMessage = "Failed to submit form";
        toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        const errorMessage = "Error submitting form";
        toast.current?.show({ severity: "warn", summary: "Warning", detail: errorMessage, life: 3000 });
      } else {
        console.error("Error submitting form", error);
      }
    }
  };

  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{title}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={handleReset}
      />
    </div>
  );


  return (
    <Dialog
      className="popup popup-card-layout"
      visible={visible}
      draggable={false}
      style={{ width: "70vw"}}
      header={header}
      modal
      onHide={handleReset}
    >
      <div className="card-wrapper">
        {!isSubmitted ? (
          <>
            <div className="form-fields-row" style={{ display: "flex", gap: "1rem", marginBottom: "1rem", marginRight: "1rem" }}>
              <div className="field" style={{ flex: 1 }}>
                <label htmlFor="formType">Form Type</label>
                <Dropdown
                  id="formType"
                  value={formType}
                  options={formTypeOptions}
                  onChange={(e) => handleFormTypeChange(e.value)}
                  placeholder="Select Form Type"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {renderDynamicFields()}
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
              <Button label="Cancel" icon="pi pi-times" onClick={handleReset} className="p-button-secondary" />
              <Button label="Save" icon="pi pi-check" onClick={handleSave} autoFocus />
            </div>
          </>
        ) : (
          <div>
            <h4>Form Type: {formType}</h4>
            <p>Your form has been submitted successfully!</p>
          </div>
        )}
      </div>
      <div className="cancel-submit-btn">
        <Button label="Cancel" className="cancel-btn" onClick={handleReset} tabIndex={3} />
        {!isSubmitted && (
          <Button label="Submit" className="submit-btn ml-2" onClick={handleSave} tabIndex={4} disabled={formType === null} />
        )}
      </div>
    </Dialog>
  );
};

export default AddFormPopup;
