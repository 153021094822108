import axios from "axios";
import authHeader from "./authHeader";

const GetFormsForExpert = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/form/getFormsForExpert`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};


const GetFormsAttributes = async (data: any) => {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/form/getFormAttributes`, data, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const SubmitFormAttribute = async (data: any) => {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT + `api/form/submitFormAttribute`, data, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  const getSubmittedFormById = async (data: any) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT}api/form/getSubmittedFormById` , data , {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      return response; 
    } catch (error) {
      return error;
    }
  };

const FormService = {
    GetFormsForExpert,
    GetFormsAttributes,
    SubmitFormAttribute,
    getSubmittedFormById,
};

export default FormService;
