import axios from "axios";
import authHeader from "./authHeader";

const GetExpertise = async () => {
  return axios
    .get(process.env.REACT_APP_BASE_API_ENDPOINT + `api/User/expert`, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    })
    .then((res) => res)
    .catch((err) => err);
};

const GetAllTherapy = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/Therapy/GetAllTherapy`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllForm = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_PROTOCOL_ENDPOINT}api/Form/GetAllForm`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const AddExpertise = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/ExpertPanel/Register`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllExpter = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/ExpertPanel/GetExpertPanel`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const GetAllExpterBindValues = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/ExpertPanel/GetExpertPanel`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const UpdateExpter = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}api/ExpertPanel/Update`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const DeleteExpter = async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_CMS_ENDPOINT}/api/ExpertPanel/Delete`, data, {
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
const ExpertiesService = {
  GetExpertise,
  GetAllTherapy,
  GetAllExpter,
  GetAllForm,
  AddExpertise,
  UpdateExpter,
  GetAllExpterBindValues,
  DeleteExpter,
};

export default ExpertiesService;
