import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import FormService from "../../services/FormService";
import { Dropdown } from "primereact/dropdown"; 

interface DynamicFieldsApiResponse {
  [key: string]: {
    value: string;  
    formfield: string; 
  };
}

interface FormResponse {
  formname: string;
  submittedvalues: string; 
}

interface ViewFormPopupProps {
  visible: boolean;
  onHide: () => void;
  title: string;
  customerid: any;
}

const ViewDetailsPopup: React.FC<ViewFormPopupProps> = ({
  visible,
  onHide,
  title,
  customerid,
}) => {
  const [forms, setForms] = useState<FormResponse[]>([]); 
  const [selectedForm, setSelectedForm] = useState<FormResponse | null>(null); 
  const [dynamicFields, setDynamicFields] = useState<DynamicFieldsApiResponse>({}); 

  useEffect(() => {
    if (visible) {
      fetchSubmittedFormDetails(customerid);
    }
  }, [visible]);

  const fetchSubmittedFormDetails = async (customerid: any) => {
    try {
      const response = await FormService.getSubmittedFormById({ customerid });
      const typedResponse = response as { status: number; data: FormResponse[] };

      if (typedResponse.status === 200) {
        setForms(typedResponse.data); 
      } else {
        console.error("Failed to fetch submitted form details", response);
      }
    } catch (error) {
      console.error("Error fetching submitted form details", error);
    }
  };

  const onFormSelect = (form: FormResponse) => {
    setSelectedForm(form);
    const parsedValues = JSON.parse(form.submittedvalues) as DynamicFieldsApiResponse;
    setDynamicFields(parsedValues);
  };

  const renderDynamicFields = () => {
    return Object.keys(dynamicFields).map((key) => {
      const { value, formfield } = dynamicFields[key];
  
      // Join values with commas if it's an array, otherwise use the value as is
      const displayValue = Array.isArray(value) ? value.join(', ') : value;
  
      return (
        <div key={key} className="view-popup-field" style={{ marginBottom: "1rem", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <label style={{ flex: 1, fontWeight: 'bold' }}>{formfield}:</label>
          <span style={{ flex: 2, marginLeft: "10px" }}>{displayValue}</span>
        </div>
      );
    });
  };
  // Custom header with title and close button
  const header = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <span>{title}</span>
      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-text popup-close-button"
        onClick={() => {
          onHide(); // Calls the onHide function to close the dialog
        }}
        aria-label="Close"
      />
    </div>
  );

  return (
    <Dialog
    className="popup popup-card-layout"
    visible={visible}
    draggable={false}
    style={{ width: "70vw" }}
    header={header} // Use custom header
    modal
    onHide={onHide}
  >
    <div className="card-wrapper">
      {/* Dropdown to select a form */}
      <div className="form-select">
        <Dropdown
          value={selectedForm}
          options={forms}
          onChange={(e) => onFormSelect(e.value)}
          optionLabel="formname"
          placeholder="Select a form"
          style={{ width: "100%" }} // Ensures dropdown takes full width
        />
      </div>

      {/* Line gap between dropdown and dynamic fields */}
      <div style={{ margin: "1rem 0" }} />

      {/* Display the selected form's details */}
      {selectedForm ? (
        <div className="form-details">
          {Object.keys(dynamicFields).length > 0 ? (
            renderDynamicFields()
          ) : (
            <div>No details available for this form.</div>
          )}
        </div>
      ) : (
        <div>Please select a form to view its details.</div>
      )}
    </div>

    {/* Close button */}
    <div className="cancel-submit-btn">
      <Button label="Close" className="cancel-btn" onClick={onHide} tabIndex={3} />
    </div>
  </Dialog>
  );
};

export default ViewDetailsPopup;
